import { useStore } from "app/context";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Modal } from "../Modal";
import { Field, Form, Formik } from "formik";
import FormField from "../form/FormField";
import { DatePickerField } from "../form/DatePickerField";
import { Button } from "../common/Button";
import * as Yup from 'yup';
import { ImportDataType } from "app/stores/admin.imports.store";
import { runInAction } from "mobx";
import { useHistory } from "react-router";
import routes from "routes";


const DateStandSchema = Yup.object({
    stand: Yup.date().required('Datum ist erfoderlich').default(new Date()),
    zusatz: Yup.string()
}).required();

type DatenStandForm = Yup.InferType<typeof DateStandSchema>;

interface IDatenStandEntryModal {
    dataType: ImportDataType;
    onClose: (fileName?: string) => void
}

export const AdminFileUploadFileNameModal = observer((props: IDatenStandEntryModal) => {
    const { uiStore, adminFileUploadUiStore, adminImportsUiStore, adminDatenStandStore: datenStandStore } = useStore();
    const history = useHistory();

    useEffect(() => {
        datenStandStore.findAll().then(res => {
            const datenStand = res.find(x => x.dataType === props.dataType);
            if (datenStand && datenStand.stand) {
                setInitialDs(datenStand.stand)
            }
        })
    }, []);


    const [initialDs, setInitialDs] = useState<Date>(new Date());
    const onClose = () => {
        adminFileUploadUiStore.newFilename = 'cancel';
        adminFileUploadUiStore.onFileUploaded = undefined;
    };

    const handleSubmit = async (values: DatenStandForm) => {
        runInAction(() => {
            adminFileUploadUiStore.newFilename = adminFileUploadUiStore.calcNewFileName(values.stand, props.dataType, values.zusatz);
            adminFileUploadUiStore.onFileUploaded = async () => {
                if (!adminFileUploadUiStore.newFilename) {
                    alert(" no new filename");
                    return;
                }
                if (!adminImportsUiStore.newJob) {
                    alert(" no new job");
                    return;
                }
                const job = adminFileUploadUiStore.prepareJobForTrigger(adminFileUploadUiStore.newFilename, adminImportsUiStore.newJob.job)
                if (!job) {
                    return;
                }
                const res: any = await adminImportsUiStore.triggerJob(job);
                history.push(routes.ADMINIMPORTS.getPath('job', res.id));

                // prevents auto-triggering in other (aup) admin-file uploads
                adminFileUploadUiStore.onFileUploaded = undefined;
            }
        })
    };

    return (
        <Modal modalId={uiStore.modalIds.adminFileUploadRenamFile} title={'Importdatei benennen'} onClose={onClose} size='large'>
            <Formik
                validationSchema={DateStandSchema}
                initialValues={{
                    stand: initialDs,
                    zusatz: undefined
                }}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, isValid, values }) => {

                    return (
                        <Form>
                            <div className="pad-1rem">
                                <strong>{adminFileUploadUiStore.orgFilename} {'->'} {adminFileUploadUiStore.calcNewFileName(values.stand, props.dataType, values.zusatz)}</strong>
                                <FormField className="is-inline" error={errors.stand ? String(errors.stand) : undefined} touched={touched.stand ? true : false} label="Datestand">
                                    <DatePickerField name="stand" />
                                </FormField>
                                <FormField className="is-inline" error={errors.stand ? String(errors.zusatz) : undefined} touched={touched.zusatz ? true : false} label="Zusatz">
                                    <Field type="text" name="zusatz" placeholder="Zusatz" />
                                </FormField>
                                <br />

                                <br />
                                <Button type="submit" className={'button is-primary'} >
                                    Namen festlegen und Import Starten
                                </Button>
                                <Button type="button" className={'button is-secondary'} onClick={onClose} >
                                    Abbrechen
                                </Button>
                                <br />
                            </div>
                            {/* For the datepicker  */}
                            <div className="pad-1rem"></div><div className="pad-1rem"></div><div className="pad-1rem"></div><div className="pad-1rem"></div><div className="pad-1rem"></div><div className="pad-1rem"></div>


                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
});