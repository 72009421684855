import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { MultiGpVM } from 'app/stores/ui/agt.multigp.ui.store';
import { Button } from 'app/components/common/Button';
import { notify } from 'app/components/common/notify';
import { formatNum } from 'app/utils';

export const MultiGp2025 = observer(() => {
	const { berichteUiiStore, agtMultiGpUiStore } = useStore();
	const [hasItems, setHasItems] = useState(false);


	const tm = new TableModel<MultiGpVM, number>();
	const cols: ITableColumn<MultiGpVM, number>[] = [];

	cols.push({
		label: 'VD',
		path: 'vd',

	});
	cols.push({
		label: 'basis',
		path: 'gupMin25Base',
		format: formatNum,

	});
	cols.push({
		label: 'gupErgebnis',
		path: 'gupMin25Count',
		cellModifier: 'border-left-green bg-green',

	});
	cols.push({
		label: 'gup Ziel 1',
		path: 'gupZiel1',
		format: formatNum

	});
	cols.push({
		label: 'gup Ziel 2',
		path: 'gupZiel2',
		format: formatNum

	});

	cols.push({
		label: 'smVorjahr',
		path: 'smVorjahr',
		format: formatNum,
		cellModifier: 'border-left-green bg-green',

	});
	cols.push({
		label: 'smErgebnis',
		path: 'smErgebnis',
		format: formatNum,

	});
	cols.push({
		label: 'smZiel1',
		path: 'smZiel1',
		format: formatNum

	});
	cols.push({
		label: 'smZiel2',
		path: 'smZiel2',
		format: formatNum

	});


	cols.push({
		label: 'akumaCount',
		path: 'akumaCount',
		format: formatNum,
		cellModifier: 'border-left-green bg-green',
	});

	cols.push({
		label: 'prime Count',
		path: 'isCurrentAkumaPrimeUserGpCount',
		format: formatNum,
	});

	cols.push({
		label: 'planer Count',
		path: 'akumaPlanerGpCount',
		format: formatNum,
	});
	cols.push({
		label: 'planer count alle',
		path: 'akumaPlanerGpCountDebug',
		format: formatNum,
	});
	cols.push({
		label: 'akumaBase',
		path: 'akumaBase',
		format: formatNum,
		//cellModifier: 'border-left-green bg-green',
	});

	cols.push({
		label: 'akumaZiel',
		path: 'akumaZiel',
		format: formatNum,
	});

	cols.push({
		label: 'leadnowCount',
		path: 'leadnowCount',
		format: formatNum,
		cellModifier: 'border-left-green bg-green',
	});
	cols.push({
		label: 'leadnowZiel1',
		path: 'leadnowZiel1',
		format: formatNum,
	});
	cols.push({
		label: 'leadnowZiel2',
		path: 'leadnowZiel2',
		format: formatNum,
	});




	tm.sortBy = 'vd';
	tm.setCols(cols);

	// tm.onRowClick = (row) => {
	// 	const p = routes.BERICHTE.getPath('bestand', 'fit', 'gup', row.data.agt.agtId.toString());
	// 	history.push(p);
	// };

	tm.idProperty = 'agtId';
	tm.idType = 'number';


	useEffect(() => {
		agtMultiGpUiStore.load().then(() => {
			let items = agtMultiGpUiStore.items;

			setHasItems(items.length > 0);
			tm.setRowData(items);
			tm.sort();
		});
	}, [tm, berichteUiiStore.selectedAgt, agtMultiGpUiStore.items]);

	const saveGup = () => {
		agtMultiGpUiStore.saveGup().then(() => {
			notify('GUP gespeichert');
		});
	}

	const saveSm = () => {
		agtMultiGpUiStore.saveSm().then(() => {
			notify('SM gespeichert');
		});
	}

	const saveAkuma = () => {
		agtMultiGpUiStore.saveAkuma().then(() => {
			notify('SM gespeichert');
		});
	}

	const saveLeadNow = () => {
		agtMultiGpUiStore.saveLeadNow().then(() => {
			notify('LeadNow gespeichert');
		});
	}


	return (
		<>
			{!hasItems ? (
				<div className="pad-1rem">Keine Daten gefunden</div>
			) : (
				<>
					<Button type='button' onClick={saveGup}>Save GUP</Button>
					<Button type='button' onClick={saveSm}>Save SM</Button>
					<Button type='button' onClick={saveAkuma}>Save Akuma</Button>
					<Button type='button' onClick={saveLeadNow}>Save LeadNow</Button>
					<Table stickyHeader={true} tm={tm} allowCsvExport={true} csvExportName="multi-gp.csv"></Table>
				</>
			)}
		</>
	);
});
