import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useStore } from 'app/context';
import { AdminUserLoginTrackingList, AdminUserLoginTrackingByYmd, AdminUserLoginTrackingByYm, AdminUserLoginTrackingPivotYm, AdminTrackingPivot, Info } from 'app/components/admin/users/UserLoginTrackingList';
import React from 'react';
import { Button } from 'app/components/common/Button';
import { AggregrationType } from 'app/components/table/table.model';
import { UserLoginVM } from 'app/stores/ui/admin.user.login.ui.store';

export interface AdminBnrDiffParamTypes {
    bnrId?: string;
}


const loginCountCol = {
    label: 'Logins',
    path: 'track.loginCount',
    agg: [
        {
            aggType: AggregrationType.sum
        }
    ]
}

const fitnessCountCol = {
    label: 'FitnessPdf',
    path: 'track.fitnessPdf',
    agg: [
        {
            aggType: AggregrationType.sum
        }
    ]
}


const pivotVd = {
    pivotCol: {
        label: 'VD',
        path: 'user.user.firstVd',
        drilldown: {
            filter: (vd: string) => { },
            drillCol: {
                label: 'VD',
                path: 'user.user.firstGs',
            }
        }
    },
};




const rowDefSteart = {
    rows: (x: UserLoginVM) => x.user?.user.steartText,
    rowCol: {
        label: '',
        path: 'user.user.steartText',
    }
}

const rowDefYM = {
    rows: (x: UserLoginVM) => x.track.ymforsort,
    rowCol: {
        label: 'Datum',
        path: 'track.ymforsort',
    }
}





const StatsDef = [
    {
        title: 'Log',
        view: <AdminUserLoginTrackingList />
    },
    {
        title: 'test',
        view: <AdminUserLoginTrackingPivotYm />
    },
    {
        title: 'Logins: Steart - VD',
        view: <AdminTrackingPivot aggCol={loginCountCol} rowDef={rowDefSteart} pivotDef={pivotVd} />
    },
    {
        title: 'Logins: YM - VD',
        view: <AdminTrackingPivot aggCol={loginCountCol} rowDef={rowDefYM} pivotDef={pivotVd} />
    },
    {
        title: 'FitnessPdf: YM - VD',
        view: <AdminTrackingPivot aggCol={fitnessCountCol} rowDef={rowDefYM} pivotDef={pivotVd} />
    },


    {
        title: 'test2',
        view: <AdminUserLoginTrackingPivotYm />
    },
    {
        title: 'Pro Tag',
        view: <AdminUserLoginTrackingByYmd />
    }, {
        title: 'Pro Monat',
        view: <>
            <AdminUserLoginTrackingByYm />
            <Info />
        </>
    },
]


const AdminUserLoginContainer = observer((props: RouteComponentProps) => {
    const { session, adminUserLoginUiStore } = useStore();
    const { bnrId } = useParams<AdminBnrDiffParamTypes>();

    const [selectedView, setSelectedView] = React.useState(StatsDef[6]);

    const handleViewChange = (view: any) => {
        setSelectedView(view);
    };

    const currentUser = session.currentUser;

    const AdminUsersNavbar = observer(() => {
        return (
            <div className="admin-navbar">
                <div className="is-left">
                    <strong>Login Tracking</strong>
                </div>
                <div className="is-right">
                    <div className="admin-actions">
                        {StatsDef.map(d => <Button type="button" key={d.title} className="button is-small" onClick={() => handleViewChange(d)}>{d.title}</Button>)}
                    </div>
                </div>
            </div>
        );
    });

    return (
        <BaseContainer classModifier="admin-container admin-users-container" sideMenu={<MainMenu {...props} />} navbar={<AdminUsersNavbar />}>
            {currentUser && (
                <>
                    <strong>{selectedView.title}</strong>
                    <div className="table-container">
                        {selectedView.view}
                    </div>
                </>
            )}
        </BaseContainer>
    );
});

export default AdminUserLoginContainer;
