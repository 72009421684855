import { useStore } from "app/context"
import { DatenStandModel } from "app/stores/datenstand.store";
import { observer } from "mobx-react"
import { useEffect } from "react";
import { ITableColumn, TableModel } from "./table/table.model";
import { Table } from "./table/table";
import { DottedTag } from "./common/DottedTag";
import { AdminImportNewButton } from "./admin/imports/AdminImportNewButton";

export const DatenStandList = observer(() => {

    const { adminDatenStandStore: datenStandStore } = useStore();
    const tm = new TableModel<DatenStandModel, string>();
    const cols: ITableColumn<DatenStandModel, string>[] = [
        {
            label: 'Alter (Tage)',
            path: 'ageInDays',
            sortBy: 'ageForSorting',
            render: (row) => {
                return <> {row.data.ageInDays !== -500 &&
                    <DottedTag color={row.data.color} text={row.data.ageInDays.toString()} />
                } </>

            }
        },
        // {
        //     label: 'Alter (Tage)',
        //     path: 'ageForSorting',

        // },
        {
            label: 'Neuer Job',
            path: 'importDataType',
            render: (row) => {
                return <AdminImportNewButton path={row.data.importDataType} title={row.data.importDataType} />
            }
        },
        {
            label: 'Datenstand',
            path: 'standFormated',
        },
        {
            label: '.. für',
            path: 'dataType',
        },


        {
            label: 'Turnus',
            path: 'cycle',
        },
        {
            label: 'Scope:Lieferung',
            path: 'incominDataTimeScope',
        },
        {
            label: 'Scope:DVS',
            path: 'dvsTimeScope',
        },
        {
            label: 'Anzahl Agts',
            path: 'agtCount',
        },
        {
            label: 'Anzahl Zeilen',
            path: 'rowCount',
        },
        {
            label: 'dbTarget',
            path: 'dbTarget',
        },

    ];

    tm.setCols(cols);
    tm.sortBy = 'ageForSorting';
    tm.sortAsc = false;
    tm.idProperty = 'dataType';
    tm.idType = 'string';

    useEffect(() => {
        datenStandStore.findAll().then(res => {
            tm.setRowData(res)

        });

    }, [datenStandStore])

    return <>
        <Table tm={tm} />

    </>
})

